import React from 'react';
import {
  ButtonGroup,
  Button,
  responsiveStyles,
  audiLightTheme,
  ThemeProvider,
} from '@audi/audi-ui-react';
import { IconNamesMerged } from '@audi/audi-ui-react/dist/cjs/components/Icon/Icon.types.mjs.cjs';
import styled, { AnyStyledComponent } from 'styled-components';
import { SocialMediaType } from '../../types/audi-footer-response.types';
import { TrackingClickEvent, useTracking } from '../../utils/tracking';
import { TrackingContext } from '../../context';

interface SocialMediaProps {
  socialMedia: SocialMediaType[];
}

const iconNameMapping: { [key: string]: IconNamesMerged } = {
  twitter: 'x',
  line: 'line-me',
};

const RightAlignedButtonGroup = styled(ButtonGroup as AnyStyledComponent)<{ variant: string }>`
  & > div {
    // custom css in order to align items in <ButtonGroup> right
    ${(t: ThemeProps): string =>
      responsiveStyles(
        {
          'justify-content': { xxs: 'flex-start', m: 'flex-end' },
        },
        t.theme.breakpoints,
      )}
    display: flex;
  }
`;

const SocialMedia: React.FC<SocialMediaProps> = ({ socialMedia }) => {
  const trackingService = React.useContext(TrackingContext);
  const { sendClickEvent } = useTracking(trackingService);
  const sendButtonClickEvent = (
    label: string,
    url: string,
    variant: string,
    openin: string,
    position: string,
  ) => {
    const event: TrackingClickEvent = {
      linkLabel: label,
      linkUrl: url,
      elementName: variant,
      headline: label,
      OpenIn: openin,
      position,
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return sendClickEvent(event);
  };
  return (
    <RightAlignedButtonGroup variant="block-buttons">
      <ThemeProvider theme={audiLightTheme}>
        {socialMedia.map((socialMediaItem) => {
          const { Id, Link } = socialMediaItem;
          const { Text, Url, Target } = Link;
          return Url ? (
            <Button
              href={Url}
              target={Target}
              onClick={() => sendButtonClickEvent(Text!, Url, 'text link', Target, '')}
              key={Id}
              aria-label={Text || `${iconNameMapping[Id] || Id}`}
              variant="icon-primary"
              icon={iconNameMapping[Id] || Id}
              rel="noopener noreferrer"
            />
          ) : null;
        })}
      </ThemeProvider>
    </RightAlignedButtonGroup>
  );
};

export default SocialMedia;
