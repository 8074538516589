import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';

type EventAction = 'content' | 'exit_link' | 'internal_link' | '';
export const setEventAction = (
  trackingService: TrackingServiceV2,
  displayMode: string,
  link: string,
): EventAction => {
  let action: EventAction = '';

  if (
    displayMode === 'new' ||
    displayMode === 'same' ||
    displayMode === '_self' ||
    displayMode === '_blank'
  ) {
    action = (trackingService?.evaluateLinkType(link) as EventAction) || '';
  } else if (
    displayMode === '_layer' ||
    displayMode === 'layer' ||
    displayMode === 'toTop' ||
    displayMode === 'language' ||
    displayMode === 'cookie-settings-layer'
  ) {
    action = 'content';
  }
  return action;
};

const completeTargetURL = (targetURL: string): string => {
  const { host } = document.location;
  if (targetURL.startsWith('/')) {
    return `${host}${targetURL}`;
  }
  return targetURL;
};

export const setTargetURL = (url: string, openInTabOrLayer: string) => {
  if (openInTabOrLayer === '_layer') {
    return completeTargetURL(url);
  }
  if (
    openInTabOrLayer === 'chatbot' ||
    openInTabOrLayer === 'language' ||
    openInTabOrLayer === 'toTop'
  ) {
    // always empty if chatbot is selected }
    return '';
  }
  return completeTargetURL(url);
};

export const removeSpecialCharacters = (text: string | undefined) => {
  if (!text) {
    return '';
  }
  return text
    .replace(/&#8203;/gi, '')
    .replace(/&zwnj;/gi, '')
    .replace(/&nbsp;/gi, '');
};
