import React from 'react';
import styled from 'styled-components';
import {
  getClassNamesForLinks,
  getIdNameForLink,
  getLinkTarget,
  onCookieModalLinkClick,
} from '../../services/helpers';
import { TrackingClickEvent, useTracking } from '../../utils/tracking';
import { Link } from '../../types/audi-footer-response.types';
import { TrackingContext } from '../../context';

type LegalLinksProps = {
  countryCode?: string;
  links?: Link[];
  openLayer: React.MouseEventHandler<HTMLAnchorElement>;
  useOneLayer?: boolean;
};

const LegalLinksStyledWrapper = styled.div`
  flex-grow: 1;
  margin-right: var(--one-footer-space-xl);

  & > ul {
    display: inline-flex;
    flex-flow: row wrap;
    list-style: none;
    margin: 0 0 calc(var(--one-footer-space-l) - var(--one-footer-space-s));
    padding: 0;
  }

  & > ul > li:not(:last-child) {
    padding-right: var(--one-footer-space-l);
  }

  & > ul > li > a {
    display: inline-block;
    margin-bottom: var(--one-footer-space-s);
  }
`;

const LegalLinks: React.FC<LegalLinksProps> = ({ countryCode, links, openLayer, useOneLayer }) => {
  const trackingService = React.useContext(TrackingContext);
  const { sendClickEvent } = useTracking(trackingService);
  const sendButtonClickEvent = (
    label: string,
    url: string,
    variant: string,
    openin: string,
    position: string,
  ) => {
    const event: TrackingClickEvent = {
      linkLabel: label,
      linkUrl: url,
      elementName: variant,
      headline: 'Bottom Bar',
      OpenIn: openin,
      position,
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return sendClickEvent(event);
  };
  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    link: Link,
    index: string,
  ): void => {
    const target = getLinkTarget(link, useOneLayer);
    const { Type, Text, Url, Target } = link;
    if (!!Type && Type === 'cookie-settings-layer' && countryCode !== 'US') {
      onCookieModalLinkClick(event);
    } else if (Target === '_layer' && useOneLayer) {
      openLayer(event);
    }
    sendButtonClickEvent(Text, Url, 'text link', target, index);
  };
  return (
    <LegalLinksStyledWrapper>
      <ul>
        {links?.map((link) => {
          return (
            <li key={`${link.Target}${link.Text}${link.Url}`}>
              <a
                className={getClassNamesForLinks(link, countryCode, useOneLayer)}
                onClick={(event) => handleClick(event, link, '')}
                href={link.Url}
                target={getLinkTarget(link, useOneLayer)}
                {...(getIdNameForLink(link) ? { id: getIdNameForLink(link) } : {})}
              >
                {link.Text}
              </a>
            </li>
          );
        })}
      </ul>
    </LegalLinksStyledWrapper>
  );
};

export default LegalLinks;
