import React from 'react';

import styled from 'styled-components';
import { useI18n } from '@oneaudi/i18n-context';
import { Icon } from '@audi/audi-ui-react';
import { LanguageLink } from '../../types/audi-footer-response.types';
import { TrackingClickEvent, useTracking } from '../../utils/tracking';
import { TrackingContext } from '../../context';

type LanguageSwitchProps = {
  links: LanguageLink[];
};

const IconWrapper = styled.div`
  & > svg {
    display: inline-block;
    margin-right: var(--one-footer-space-s);
  }
`;

const FooterLanguageSwitchWrapperStyled = styled.div`
  position: relative;
  top: -3px;
`;

const FooterLanguageSwitchStyled = styled.div`
  align-items: center;
  color: var(--one-footer-color-white);
  display: flex;
  flex-wrap: nowrap;
  margin: 0;

  & > select {
    appearance: none;
    background-color: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg height='24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5.5 5.5L18 10' color='white' fill='none' stroke='currentColor'></path></svg>");
    background-position: right 0px top -3px; /* Change the px value here to change the distance */
    background-repeat: no-repeat, repeat;
    background-size: auto;
    border: 0;
    color: var(--one-footer-color-white);
    height: auto;
    line-height: 1.8rem;
    padding: 0 24px 0 0;
    text-decoration: none;
    width: auto;

    &::-ms-expand {
      display: none;
    }

    &:hover {
      border: 0;
      color: var(--one-footer-color-white);
    }

    &:focus {
      color: var(--one-footer-color-white);
      outline: none;
    }

    & > option {
      color: var(--one-footer-color-black);
      font-weight: normal;
    }
  }
`;

const LanguageSwitch: React.FC<LanguageSwitchProps> = ({ links }) => {
       const trackingService = React.useContext(TrackingContext);
       const { sendClickEvent } =
          useTracking(trackingService);
              const sendButtonClickEvent = (
                label: string,
                url: string,
                variant: string,
                openin: string,
                position: string,
              ) => {
                const event: TrackingClickEvent = {
                  linkLabel: label,
                  linkUrl: url,
                  elementName: variant,
                  headline: label,
                  OpenIn: openin,
                  position,
                };
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return sendClickEvent(event);
              };
  const onChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    /* Ignore next line for code coverage, because... */
    /* istanbul ignore next */
    if (typeof window !== 'undefined') {
      const selected = links?.find((e) => e.IsSelected);
      sendButtonClickEvent(selected!.Text,selected!.Url,'drop down','language',"");
      window?.open(event.target.value, '_self');
    }
  };

  const selectAriaLabel = `${useI18n({
    defaultMessage: 'Please select',
    id: 'axs.ui.country-selector.country.select.placeholder',
  })} ${useI18n({
    defaultMessage: 'country',
    id: 'axs.ui.country-selector.country.select.label',
  })}`;

  return (
    <FooterLanguageSwitchWrapperStyled>
      <FooterLanguageSwitchStyled>
        <IconWrapper>
          <Icon name="languages" size="s" />
        </IconWrapper>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <select
          defaultValue={links?.find((e) => e.IsSelected)?.Url}
          aria-label={selectAriaLabel}
          name="languageToggle"
          onChange={onChange}
        >
          {links.map((link) => {
            return (
              <option key={`${link.Text}${link.Url}`} value={link.Url}>
                {link.Text}
              </option>
            );
          })}
        </select>
      </FooterLanguageSwitchStyled>
    </FooterLanguageSwitchWrapperStyled>
  );
};

export default LanguageSwitch;
