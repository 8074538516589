import React, { MouseEventHandler } from 'react';
import { responsiveStyles } from '@audi/audi-ui-react';
import styled from 'styled-components';
import {
  getClassNamesForLinks,
  getIdNameForLink,
  getLinkTarget,
  onCookieModalLinkClick,
} from '../../services/helpers';
import { Link } from '../../types/audi-footer-response.types';
import { TrackingClickEvent, useTracking } from '../../utils/tracking';
import { TrackingContext } from '../../context';

type LinksProps = {
  countryCode?: string;
  categoryIndex: string;
  headline: string;
  links: Link[];
  useOneLayer?: boolean;
  openLayer: MouseEventHandler;
};

const FooterLinksWrapperStyled = styled.div`
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  ${(t: ThemeProps): string =>
    responsiveStyles(
      {
        'background-color': { m: 'var(--one-footer-color-black)' },
        height: { m: 'auto' },
        margin: { m: '0' },
        'max-height': { m: 'initial' },
        opacity: { m: '1' },
      },
      t.theme.breakpoints,
    )}
  transition: all 240ms var(${(t: ThemeProps): string => t.theme.easing});

  & > ul {
    padding: calc(var(--one-footer-space-l) - var(--one-footer-space-m) / 2) 0;
    /* stylelint-disable */
    ${(t: ThemeProps): string =>
      responsiveStyles(
        {
          padding: { m: '0' },
        },
        t.theme.breakpoints,
      )}

    & > li {
      padding: 0 var(--one-footer-side-spacing);
      ${(t: ThemeProps): string =>
        responsiveStyles(
          {
            padding: { m: '0 0 var(--one-footer-space-s)' },
          },
          t.theme.breakpoints,
        )}
      /* stylelint-enable */
      & > a {
        display: block;
        padding: calc(var(--one-footer-space-m) / 2) 0;
        width: 100%;
        ${(t: ThemeProps): string =>
          responsiveStyles(
            {
              display: { m: 'inline' },
              padding: { m: '0' },
              width: { m: 'auto' },
            },
            t.theme.breakpoints,
          )}
      }
    }

    & > li:last-of-type {
      ${(t: ThemeProps): string =>
        responsiveStyles(
          {
            padding: { m: '0' },
          },
          t.theme.breakpoints,
        )}
    }
  }
`;

const Links: React.FC<LinksProps> = ({
  categoryIndex,
  headline,
  countryCode,
  links,
  useOneLayer,
  openLayer,
}) => {
  const trackingService = React.useContext(TrackingContext);
  const { sendClickEvent } = useTracking(trackingService);
  const sendButtonClickEvent = (
    label: string,
    url: string,
    variant: string,
    openin: string,
    position: string,
  ) => {
    const event: TrackingClickEvent = {
      linkLabel: label,
      linkUrl: url,
      elementName: variant,
      headline,
      OpenIn: openin,
      position: `${categoryIndex}-${position}`,
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return sendClickEvent(event);
  };
  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    link: Link,
    index: string,
  ): void => {
    const target = getLinkTarget(link, useOneLayer);
    const { Type, Text, Url, Target } = link;
    if (!!Type && Type === 'cookie-settings-layer' && countryCode !== 'US') {
      onCookieModalLinkClick(event);
    } else if (Target === '_layer' && useOneLayer) {
      openLayer(event);
    }
    sendButtonClickEvent(Text, Url, 'text link', target, index);
  };
  return (
    <FooterLinksWrapperStyled>
      <ul>
        {links.map((link, i) => {
          const randomNumber = Math.floor(Math.random() * 1000) + 1;
          return (
            <li
              key={`${randomNumber}${link.Target}${link.Text}${link.Url}`}
              // overview links are supported in Headful CMS only
              // in headless cms we discontinue this feature => https://collaboration.msi.audi.com/jira/browse/WEBSUPPORT-37598
              data-is-overview-link={link.IsOverview}
            >
              <a
                className={getClassNamesForLinks(link, countryCode, useOneLayer)}
                onClick={(event) => handleClick(event, link, (i + 1).toString())}
                href={link.Url}
                target={getLinkTarget(link, useOneLayer)}
                {...(getIdNameForLink(link) ? { id: getIdNameForLink(link) } : {})}
              >
                {link.Text}
              </a>
            </li>
          );
        })}
      </ul>
    </FooterLinksWrapperStyled>
  );
};

export default Links;
