/* eslint-disable @typescript-eslint/no-shadow */

import * as React from 'react';
import { TrackingDataV2, TrackingEventV2, TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { setEventAction, setTargetURL } from './trackingUtils';
import { APP_ID } from '../../environment';

// Tracking component names should be static and not change if feature app name changes
const COMPONENT_NAME_WITH_DASH = 'footer';
const COMPONENT_NAME_WITH_SPACE = 'footer';
export const APP_NAME = APP_ID.replace('oneaudi-feature-app-', '').replace(/-/g, ' ');
type EventAction =
  | 'page_load'
  | 'step'
  | 'opt-in'
  | 'view_change'
  | 'internal_link'
  | 'impression'
  | 'feature_app_ready'
  | 'exit_link'
  | 'download'
  | 'image'
  | 'video'
  | 'configuration'
  | 'conflict'
  | 'favorite'
  | 'call'
  | 'anchor'
  | 'email'
  | 'content'
  | 'navigation'
  | 'tooltip'
  | 'error'
  | 'filter'
  | 'form_field'
  | 'non_interaction'
  | 'print'
  | 'user_account_change'
  | 'rating'
  | 'compare'
  | 'order';

export type TrackingClickEvent = {
  name?: string;
} & {
  linkLabel: string;
  linkUrl: string;
  elementName: string;
  headline: string;
  OpenIn: string;
  position: string;
};

export type TrackClickEvent = (event: TrackingClickEvent) => void;

type CreateEventProps = {
  attributes?: TrackingEventV2['attributes'];
  action: EventAction;
  name: string;
};

const createEvent = ({ attributes, action, name }: CreateEventProps): TrackingDataV2 => ({
  event: {
    attributes: {
      componentName: APP_NAME,
      ...attributes,
    },
    eventInfo: {
      eventAction: action,
      eventName: name,
    },
  },
});

const trackClickEvent = (
  tracking: TrackingServiceV2,
  { linkUrl, linkLabel, elementName, headline, OpenIn, position }: TrackingClickEvent,
): void => {
  const eventAction = tracking.evaluateLinkType(linkUrl);
  if (linkUrl && !eventAction) {
    // do not track click event if link type could not be evaluated
    return;
  }

  let eventName = 'on footer link';
  if (OpenIn === 'language') {
    eventName = 'to change language';
  } else if (OpenIn === 'toTop') {
    eventName = "on 'back to top'";
  }

  const event = {
    event: {
      attributes: {
        componentName: COMPONENT_NAME_WITH_DASH,
        label: linkLabel,
        clickID: OpenIn === '_layer' ? 'layer-content' : '',
        pos: position,
        targetURL: setTargetURL(linkUrl, OpenIn),
        value: headline,
        elementName,
      },
      eventInfo: {
        eventAction: setEventAction(tracking, OpenIn, linkUrl),
        eventName: `${COMPONENT_NAME_WITH_SPACE} - click ${eventName}`,
      },
    },
  };
  tracking.track(event);
};

export const useTracking = (
  service?: TrackingServiceV2,
): {
  service: TrackingServiceV2 | undefined;
  registerImpressionTracking: (el: React.MutableRefObject<null>) => void;
  sendReadyEvent: () => void;
  sendClickEvent: (event: TrackingClickEvent) => void;
} => {
  if (typeof window === 'undefined' || typeof service === 'undefined') {
    // skip tracking initialization when no window object is present (=> SSR)
    return {
      service: undefined,
      registerImpressionTracking: () => null,
      sendReadyEvent: () => null,
      sendClickEvent: () => null,
    };
  }

  const sendReadyEvent = React.useCallback(() => {
    const attribute = {
      action: 'feature_app_ready' as EventAction,
      name: `${COMPONENT_NAME_WITH_SPACE} - feature app ready`,
      attributes: {
        componentName: COMPONENT_NAME_WITH_DASH,
        elementName: '',
        label: '',
        clickID: '',
        pos: '',
        targetURL: '',
        value: '',
      },
    };
    const toBeTracked = createEvent(attribute);
    return service.track(toBeTracked as unknown as TrackingDataV2);
  }, [service]);

  const registerImpressionTracking = React.useCallback(
    (ref: React.MutableRefObject<null>) => {
      if (service && ref?.current) {
        service.registerImpressionTracking(ref.current, () => ({
          event: {
            eventInfo: {
              eventAction: 'impression',
              eventName: `${COMPONENT_NAME_WITH_SPACE} - impression`,
            },
            attributes: {
              componentName: COMPONENT_NAME_WITH_DASH,
              label: '',
              targetURL: '',
              clickID: '',
              elementName: '',
              pos: '',
            },
          },
        }));
      }
    },
    [service],
  );

  const sendClickEvent = React.useCallback(
    (event: TrackingClickEvent) => trackClickEvent(service, event),
    [],
  );

  return {
    service,
    registerImpressionTracking,
    sendReadyEvent,
    sendClickEvent,
  };
};
